<template>
    <div class="exam" @contextmenu.prevent="">

            <div class="header-bg">  </div>
               <div class="container">
                <div class="top">
                    <div  class="title">{{paperTitle}}</div>
                    <div class="time" v-if="time">
                        <img src="../assets/img/time.png" alt="">
                        <span>剩余时间</span>
                        <countdown
                            size="large"
                            theme-color="#fff"
                            :hour="hour+''"
                            is-actual-cb
                            @getTime='getTime'
                            :minute="min+''"
                        ></countdown>
                    </div>
                </div>
                <div class="bot">
                    <div class="topic">
                        <div style="margin: 10px 18px">
                        <div class="answer_card">
                            <div class="title">答题卡</div>
                        </div>

                        <div class="signlist">
                            <div style="display: flex">
                                <div class="title">答对：<span style="color: #00C94C">{{rightCount}}</span></div>
                                <div class="title" style="margin-left: 50px">答错：<span style="color: #FF3B30">{{errorCount}}</span></div>
                            </div>
                            <div class="listbox">
                                <div class="item"
                                    @click="handleClick(index)"
                                    v-for="(item,index) in questionList"
                                    :key="index"
                                     :class="[item.selectAnswer&&item.selectAnswer.length>0?(item.selectAnswer===item.rightAnswer)?'yes':'error':'no',index===idx?'new':'']"
                                    >
                                    {{index+1}}
                                    <div class="current">当前</div>
                                </div>
                            </div>
                        </div>
                            <div class="setting">

                                <div class="title">设置</div>

                                <div class="item1">
                                    <div>答对自动下一题</div>
                                    <el-switch v-model="autoNext" active-color="#00C94C" inactive-value="0" active-value="1" @change="autoNextSetting"></el-switch>
                                </div>
                                <div class="item2">
                                <div class="btn" v-if="questionList.length>0" @click="handleSubmit">我要交卷</div>
                                </div>
                            </div>

                    </div>
                    </div>

                    <div class="right">
                        <div class="subject">

                            <div class="titlebox">
                                <div class="title">{{type}}题</div>
                                <div class="title_pub">{{currentQuestion.type===1?'可以选择多个答案':'只能选择唯一答案'}}</div>
                            </div>
                            <div style="display: flex;flex-direction: column;">
                            <div style="flex: 1;">
                            <div class="exercises">
                                <div class="type">{{type}}题</div>
                                {{idx+1}}/{{questionList.length}}、<span v-html="currentQuestion.title"></span></div>
                            <div class="radiobox">
                                <!-- 单选题 -->
                                <el-radio
                                        v-show="(currentQuestion.type !== 1)"
                                        v-for="(item,index) in (currentQuestion.options||[])"
                                        :key="item.title"
                                        class="radio"
                                        v-model="radio"
                                        :disabled="(currentQuestion.selectAnswer&&currentQuestion.selectAnswer.length>0)||checking"
                                        :label="item.key"

                                >
                                    <div style="display: flex;align-items: center">

                                        <div v-if="currentQuestion.selectAnswer===item.key">
                                            <img style="width: 33px;height: 33px;flex-shrink: 0"  :src="currentQuestion.selectAnswer===currentQuestion.rightAnswer?require('../assets/img/success.png'):require('../assets/img/error.png')"/>
                                        </div>
                                        <div v-else style="flex-shrink: 0;width: 32px;height: 32px;border: 1px solid #E6E6E6;border-radius: 32px;text-align: center;line-height: 32px;cursor: pointer"> {{item.key}}</div>

                                        <div v-if="currentQuestion.selectAnswer===item.key">

                                            <div class="label success" v-if="currentQuestion.selectAnswer===currentQuestion.rightAnswer"
                                            v-html="item.value">


                                            </div>
                                            <div class="label error" v-else v-html="item.value">


                                            </div>

                                        </div>
                                        <div class="label" v-else v-html="item.value">

                                        </div>

                                    </div>

                                </el-radio>
                                <!-- 多选题 -->
                                <el-checkbox
                                        v-show="(currentQuestion.type===1)"
                                        v-for="(item,index) in currentQuestion.options||[]"
                                        :key="index"
                                        class="radio"
                                        v-model="checked"
                                        :disabled="(currentQuestion.selectAnswer&&currentQuestion.selectAnswer.length>0)||checking"
                                        :label="item.key"
                                >
                                    <div style="display: flex;align-items: center">

                                        <div v-if="currentQuestion.selectAnswer&&currentQuestion.selectAnswer.indexOf(item.key)!==-1">
                                            <img style="width: 33px;height: 33px;flex-shrink: 0"  :src="currentQuestion.selectAnswer===currentQuestion.rightAnswer?require('../assets/img/success.png'):require('../assets/img/error.png')"/>
                                        </div>
                                        <div v-else class="myCheckbox" :class="checked.indexOf(item.key)!==-1?'checked':''">{{item.key}}</div>

                                        <div v-if="currentQuestion.selectAnswer&&currentQuestion.selectAnswer.indexOf(item.key)!==-1">

                                            <div class="label success" v-if="currentQuestion.selectAnswer===currentQuestion.rightAnswer"
                                            v-html="item.value">


                                            </div>
                                            <div class="label error" v-else v-html="item.value">

                                            </div>

                                        </div>
                                        <div class="label" v-else v-html="item.value">

                                        </div>

                                    </div>

                                </el-checkbox>

                            </div>

                            <div  class="btn">

                                <div>
                                    <button class="submit"
                                            v-if="currentQuestion.type===1&&!checking&&!currentQuestion.selectAnswer"
                                            @click="multipleAnswer">提交答案</button>
                                </div>
                                <div style="display: flex;margin-top: 20px">

                                <button @click="handelUp" class="up">上一题</button>
                                <button @click="handelGo" class="go" :disabled="idx===questionList.length-1">下一题</button>
                                </div>
                            </div>
                            </div>
                                <div style="flex-shrink: 0">
                            <div class="ansbox" v-if="currentQuestion.rightAnswer">
                                <div class="answer">答案：<span class="active">{{currentQuestion.rightAnswer}}</span></div>
                            </div>

                            <div class="parse" v-if="currentQuestion.selectAnswer">
                                <div class="title">试题解析</div>
                                <div class="desc" v-html="currentQuestion.analysis||'暂无解析'">
                                </div>

                            </div>
                            </div>
                            </div>
                        </div>

                    </div>
                </div>
               </div>

        <Footer/>
        <div class="mongolia" v-if="isShow">
            <div class="box">
                <div class="title">
                    <img style="width: 32px;height: 32px" src="../assets/img/juan.png"/>
                    交卷确认窗口
                    <img class="close" @click="isShow=false" v-if="timeover" src="../assets/img/del.png" alt="">
                </div>
                <div class="content">
                    <p>操作提示： </p>
                    <p>1、点击【现在交卷】,将提交考试成绩，结束考试。 </p>
                    <p>2、点击【继续答题】，将继续答题，倒计时结束无法继续答题。</p>
                </div>
                <div class="button">
                    <button @click="isShow=false" v-if="timeover">继续答题</button>
                    <button @click="submitPaper" class="over" v-loading.fullscreen.lock="fullscreenLoading" element-loading-text="正在提交试卷，请稍候">现在交卷</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Header from "../components/Header.vue"
import Footer from "../components/Footer.vue"
import { GetTextList,PostTextList,GetTryList,SubmitAnswer } from "../api/text";

export default {
    components: {
        Header,
        Footer,
    },
    data() {
        return {
            btnShow:false,
            fullscreenLoading: false,
            isFullScreen: false, //默认不全屏
            bool:true,
            timeover : true,
            idx : 0,
            paperTitle:'',
            min : 0,
            hour: 0,
            time: 0,
            type:'单选',
            isOver : false,
            isShow : false,
            radio : null,
            checked : [],
            paperInfo:null,
            questionList:[],
            currentQuestion:{},
            rightCount:0,
            errorCount:0,
            //检查答案中
            checking:false,
            autoNext:this.$store.state.user.autoNext,
            //回答结果记录
            answerResult:
                {
                    paperId:'',
                    question:[],
                    startTime:'',
                    endTime:'',
                    accountId:this.$store.state.user.category.id
            },
            textTime:{
                h:0,
                m:0,
                s:0
            }
        }
    },

    watch:{
        //单选判断题
        radio(newVal) {

            if(newVal.length>0) {

                this.submitAnswer(newVal);

            }
        }

    },
    created () {


        let d=new Date()
        this.answerResult.startTime = `${d.getFullYear()}-${d.getMonth()+1}-${d.getDate()} ${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`
            // window.addEventListener('beforeunload',()=>{
            //     sessionStorage.setItem('state',JSON.stringify(this.sendlists.question))
            //     sessionStorage.setItem('textTime',JSON.stringify(this.textTime))
            //     sessionStorage.setItem('idx',JSON.stringify(this.idx))
            //     //this.$fullscreen.enter()
            // })

        if(this.$route.query.id){
            let state=JSON.parse(window.sessionStorage.getItem("state"))
            let times=JSON.parse(window.sessionStorage.getItem("textTime"))
            let idx=JSON.parse(window.sessionStorage.getItem("idx"))
            GetTryList().then(res=>{        //模拟试卷列表
                this.text=res.data;
                this.questionList=res.data.list;
                if(this.questionList.length>0){
                    this.toggleFullScreen();
                    this.initCurrentQuestion()
                }

                this.questionList.forEach(item=>{

                    this.answerResult.question.push({id:item.id,answer:''})
                });

                this.answerResult.paperId=res.data.paperId;
                this.paperTitle = res.data.paperTitle;
                this.time=res.data.answerTime

                this.hour=this.time/60;
                this.min=this.time%60;


                if(state.length<2){         //判断本地数据是否存在，不存在就生成数据
                   // this.$fullscreen.enter()
                    this.time=res.data.answerTime+''

                  //  this.hour=parseInt((this.time)/60)
                  //  this.min=this.time%60;

                }else{              //本地数据存在，提取本地数据
                   // this.$fullscreen.enter()
                    this.idx=idx
                    this.hour=times.h
                    this.min=times.m

                }
            })
        }else{
            let state=JSON.parse(window.sessionStorage.getItem("state"))
            let times=JSON.parse(window.sessionStorage.getItem("textTime"))
            let idx=JSON.parse(window.sessionStorage.getItem("idx"))
            GetTextList(this.$store.state.user.category.id).then(res=>{       //综合试卷列表
                if(res){
                    this.answerResult.paperId=res.data.paperId
                    this.paperTitle = res.data.paperTitle
                    if(state.length<2){             //判断本地数据是否存在
                       // this.$fullscreen.enter()
                        this.time=res.data.answerTime+''
                        this.answerResult.question=[]
                        this.hour=this.time/60;
                        this.min=this.time%60;
                        this.questionList=res.data.list;
                        if(this.questionList.length>0){
                            this.toggleFullScreen();
                            this.initCurrentQuestion()
                        }
                        this.questionList.forEach(item=>{

                            this.answerResult.question.push({id:item.id,answer:''})
                        });
                        // this.questionList.forEach(item => {        //生成答案列表
                        //     this.answerResult.question.push({
                        //         id:item.id,
                        //         answer:'',
                        //         ans:[]
                        //     })
                        // });
                    }else{
                      //  this.$fullscreen.enter()
                        this.idx=idx
                        this.hour=times.h
                        this.min=times.m

                    };
                } else{
                    this.$router.push( {path:'/exam',query:{id:1}})
                    }
            })
        }
    },

    methods: {

        //全屏模式
        async toggleFullScreen(){
            await this.$fullscreen.toggle(document.body,{
                callback:(full=>{
                    this.isFullscreen=full
                })
            },true)
        },

        // 判断全屏
        checkFull() {
            //判断浏览器是否处于全屏状态 （需要考虑兼容问题）
            //火狐浏览器
            let isFull =
                document.mozFullScreen ||
                document.fullScreen ||
                //谷歌浏览器及Webkit内核浏览器
                document.webkitIsFullScreen ||
                document.webkitRequestFullScreen ||
                document.mozRequestFullScreen ||
                document.msFullscreenEnabled;
            if (isFull === undefined) {
                isFull = false;
            }
            return isFull;
        },

        getTime(t){
            this.textTime.h=t.h;
            this.textTime.m=t.m+'';
            this.textTime.s=t.s;

            if(t==='over'){

                this.$alert("考试时间结束,已为您自动交卷", '', {
                        confirmButtonText: '确定'
                })
                this.submitPaper()

            }
        },
        handleClick(index) {
            this.idx = index
            this.initCurrentQuestion();

        },
        //初始化当前题目
        initCurrentQuestion(){
            this.currentQuestion = this.questionList[this.idx];
            this.type = this.formatType(this.currentQuestion.type);
            this.radio=''
            this.checked=[]

        },
        //多选题作答
        multipleAnswer(){

            if(this.checked.length<2){

                this.$message.error('至少选择两个答案')
                return
            }

            this.submitAnswer(this.checked.join(''))

        },
        //提交答案
        submitAnswer(answer){

            this.answerResult.question[this.idx].answer=answer;

            this.checking=true;

            SubmitAnswer({
                answer:answer,
                subjectId:this.currentQuestion.id,
                categoryId:this.currentQuestion.questionCategory,
                status:'0',
                accountId:this.$store.state.user.category.id
            }).then(res=>{
                this.questionList[this.idx].selectAnswer = answer;
                this.questionList[this.idx].rightAnswer=res.data.rightAnswer;
                this.questionList[this.idx].analysis=res.data.analysis;
                this.currentQuestion=this.questionList[this.idx];

                this.rightCount=this.questionList.filter(item=>item.selectAnswer&&item.selectAnswer===item.rightAnswer).length;

                this.errorCount=this.questionList.filter(item=>item.selectAnswer&&item.selectAnswer!==item.rightAnswer).length;

                if(this.autoNext==='1'&&res.data.status===0){

                        this.handelGo();

                }

                //所有题目已答完
                if(this.questionList.length===this.questionList.filter(item=>item.selectAnswer).length){

                    this.submitPaper();

                }


            }).finally(()=>{
                this.checking=false;
            })

        },

        handelUp(){

            if(this.idx>0) {
                this.idx--;
                this.initCurrentQuestion()
            }

        },
        //检查是否已答完所有题目
        answerOver(){

            return this.questionList.length===this.questionList.filter(item=>item.selectAnswer).length;

        },
        handelGo(){

            if(this.idx<this.questionList.length-1){

                this.idx++;
                this.initCurrentQuestion()

            }

        },
        //自动下一题设置
        autoNextSetting(value){

            this.$store.commit('user/saveAutoNext',value)

        },
        //格式化题型
        formatType(type){

            switch (type) {

                case 1:
                    return '多选';
                case 2:
                    return '判断';

                case 3:
                    return '单选';

                default:return '单选';
            }
        },
        //交卷
        handleSubmit(){

            if(this.answerOver()){

                this.isShow=true
            }else{

                this.$confirm('还没有完成全部答题是否现在交卷?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {

                    this.submitPaper();

                })

            }

        },
        //交卷
        submitPaper(){

            let d=new Date();
            this.answerResult.endTime = `${d.getFullYear()}-${d.getMonth()+1}-${d.getDate()} ${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`

                PostTextList(this.answerResult).then(res=>{

                    this.$message.success('交卷成功');
                    this.$router.push({
                        path:'/result',
                        query:{
                            id:this.answerResult.paperId,
                            rounds:res.data||1
                        }
                    })

                })

        },
        backChenge(e){
            let that = this
                if(this.bool){
                    this.$confirm('退出页面将自动交卷，是否继续？','提示',{
                        confirmButtonText:'确定',
                        cancelButtonText: '取消',
                        closeOnPressEscape:false,
                        closeOnClickModal:false,
                        closeOnHashChange:false,
                        type:'warning',
                    }).then(()=>{
                        that.bool=false
                        that.fullscreenLoading = true;
                        that.submitPaper()
                    }).catch((arr)=>{
                        that.toggleFullScreen()
                    })
                }
        },
         stopF5Refresh() {
                document.onkeydown = function (e) {
                    var evt = window.event || e;
                    var code = evt.keyCode || evt.which;
                    if (code == 116 ) {
                        if (evt.preventDefault) {
                            evt.preventDefault();
                        } else {
                            evt.keyCode = 0;
                            evt.returnValue = false
                        }
                    }
                }
            },


        },

    mounted () {
        // document.getElementById('clk').click()
        // this.toggle()
        this.stopF5Refresh()
        // this.$rightClick(MouseEvent)
        // if(window.history && window.history.pushState){
        //     let state={
        //         title:'温馨提示：退出页面将会自动交卷',
        //     }
        //     window.history.pushState(state,null,null);
        //     window.addEventListener('popstate',this.backChenge,false)
        // };

        // window.onresize =  ()=> {     //监测退出
        //
        //     if (!this.checkFull()&&this.isFullscreen) {
        //
        //        this.$message.success('全屏:'+this.isFullscreen)
        //         this.isFullscreen = false;
        //     }else {
        //         this.isFullscreen=true;
        //     }
        //
        // }


    },
    destroyed () {
        sessionStorage.setItem('state',JSON.stringify([{"answer":"","id":"","ans":[]}]))
        sessionStorage.setItem('textTime',this.time)
        sessionStorage.setItem('idx',this.time)
        this.$fullscreen.exit()
    },
    beforeDestroy () {
        this.bool=false
        this.fullscreenLoading = false;
        sessionStorage.setItem('state',JSON.stringify([{"answer":"","id":"","ans":[]}]))
        sessionStorage.setItem('idx',this.time)

    },
}
</script>

<style lang="less"  scoped>
@import url(../assets/css/Exam.css);

.radio{

.label{
    margin-left: 10px;
    color: #000;
    white-space: normal;
    word-wrap: break-word;
    word-break: break-all;
    line-height: 22px;
    &:hover{
        color: #3B89FF;
    }
}
.success{

    color: #00C94C;
}
.error{
    color: #FF3B30;
}
}
</style>
